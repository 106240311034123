import { pdf } from "./pdf";

export const cmTemplate = (groupStamp: string) => ({
  "schemas": [
    {
      "personLastName": {
        "type": "text",
        "content": "personLastName",
        "position": {
          "x": 34.13,
          "y": 55.77
        },
        "width": 78.34,
        "height": 5.76,
        "rotate": 0,
        "alignment": "left",
        "verticalAlignment": "top",
        "fontSize": 13,
        "lineHeight": 1,
        "characterSpacing": 0,
        "fontColor": "#000000",
        "backgroundColor": "",
        "opacity": 1,
        "strikethrough": false,
        "underline": false,
        "required": false,
        "readOnly": false,
        "fontName": "Roboto"
      },
      "personFirstName": {
        "type": "text",
        "content": "personFirstName",
        "position": {
          "x": 124.3,
          "y": 56.77
        },
        "width": 78.34,
        "height": 5.76,
        "rotate": 0,
        "alignment": "left",
        "verticalAlignment": "top",
        "fontSize": 13,
        "lineHeight": 1,
        "characterSpacing": 0,
        "fontColor": "#000000",
        "backgroundColor": "",
        "opacity": 1,
        "strikethrough": false,
        "underline": false,
        "required": false,
        "readOnly": false,
        "fontName": "Roboto"
      },
      "personNrn": {
        "type": "text",
        "content": "personNrn",
        "position": {
          "x": 55.04,
          "y": 75.83
        },
        "width": 78.34,
        "height": 5.76,
        "rotate": 0,
        "alignment": "left",
        "verticalAlignment": "top",
        "fontSize": 13,
        "lineHeight": 1,
        "characterSpacing": 0,
        "fontColor": "#000000",
        "backgroundColor": "",
        "opacity": 1,
        "strikethrough": false,
        "underline": false,
        "required": false,
        "readOnly": false,
        "fontName": "Roboto"
      },
      "personAddressStreet": {
        "type": "text",
        "content": "personAddressStreet",
        "position": {
          "x": 34.66,
          "y": 62.01
        },
        "width": 69.87,
        "height": 5.76,
        "rotate": 0,
        "alignment": "left",
        "verticalAlignment": "top",
        "fontSize": 13,
        "lineHeight": 1,
        "characterSpacing": 0,
        "fontColor": "#000000",
        "backgroundColor": "",
        "opacity": 1,
        "strikethrough": false,
        "underline": false,
        "required": false,
        "readOnly": false,
        "fontName": "Roboto"
      },
      "check": {
        "type": "text",
        "content": "✓",
        "position": {
          "x": 24.97,
          "y": 141.92
        },
        "width": 8.49,
        "height": 7.35,
        "rotate": 0,
        "alignment": "left",
        "verticalAlignment": "top",
        "fontSize": 13,
        "lineHeight": 1,
        "characterSpacing": 0,
        "fontColor": "#000000",
        "backgroundColor": "",
        "opacity": 1,
        "strikethrough": false,
        "underline": false,
        "required": false,
        "readOnly": false,
        "fontName": "Roboto"
      },
      "groupStamp": {
        "type": "image",
        "content": groupStamp,
        "position": {
          "x": 116.42,
          "y": 224.98
        },
        "width": 65.67,
        "height": 21.21,
        "rotate": 0,
        "opacity": 1,
        "required": false,
        "readOnly": false
      },
      "personAddressNumber": {
        "type": "text",
        "content": "personAddressNumber",
        "position": {
          "x": 115.3,
          "y": 62.22
        },
        "width": 20.13,
        "height": 5.76,
        "rotate": 0,
        "alignment": "left",
        "verticalAlignment": "top",
        "fontSize": 13,
        "lineHeight": 1,
        "characterSpacing": 0,
        "fontColor": "#000000",
        "backgroundColor": "",
        "opacity": 1,
        "strikethrough": false,
        "underline": false,
        "required": false,
        "readOnly": false,
        "fontName": "Roboto"
      },
      "personAddressPostalcode": {
        "type": "text",
        "content": "personAddressPostalcode",
        "position": {
          "x": 38.25,
          "y": 69.57
        },
        "width": 20.13,
        "height": 5.76,
        "rotate": 0,
        "alignment": "left",
        "verticalAlignment": "top",
        "fontSize": 13,
        "lineHeight": 1,
        "characterSpacing": 0,
        "fontColor": "#000000",
        "backgroundColor": "",
        "opacity": 1,
        "strikethrough": false,
        "underline": false,
        "required": false,
        "readOnly": false,
        "fontName": "Roboto"
      },
      "personAddressPlace": {
        "type": "text",
        "content": "personAddressPlace",
        "position": {
          "x": 69.68,
          "y": 68.99
        },
        "width": 46.32,
        "height": 5.76,
        "rotate": 0,
        "alignment": "left",
        "verticalAlignment": "top",
        "fontSize": 13,
        "lineHeight": 1,
        "characterSpacing": 0,
        "fontColor": "#000000",
        "backgroundColor": "",
        "opacity": 1,
        "strikethrough": false,
        "underline": false,
        "required": false,
        "readOnly": false,
        "fontName": "Roboto"
      },
      "personAddressCountry": {
        "type": "text",
        "content": "personAddressCountry",
        "position": {
          "x": 137.57,
          "y": 69.15
        },
        "width": 53.46,
        "height": 5.76,
        "rotate": 0,
        "alignment": "left",
        "verticalAlignment": "top",
        "fontSize": 13,
        "lineHeight": 1,
        "characterSpacing": 0,
        "fontColor": "#000000",
        "backgroundColor": "",
        "opacity": 1,
        "strikethrough": false,
        "underline": false,
        "required": false,
        "readOnly": false,
        "fontName": "Roboto"
      },
      "groupName": {
        "type": "text",
        "content": "groupName",
        "position": {
          "x": 58.16,
          "y": 92.18
        },
        "width": 78.34,
        "height": 5.76,
        "rotate": 0,
        "alignment": "left",
        "verticalAlignment": "top",
        "fontSize": 13,
        "lineHeight": 1,
        "characterSpacing": 0,
        "fontColor": "#000000",
        "backgroundColor": "",
        "opacity": 1,
        "strikethrough": false,
        "underline": false,
        "required": false,
        "readOnly": false,
        "fontName": "Roboto"
      },
      "groupAddressFull": {
        "type": "text",
        "content": "groupAddressFull",
        "position": {
          "x": 32.97,
          "y": 97.95
        },
        "width": 150,
        "height": 5.76,
        "rotate": 0,
        "alignment": "left",
        "verticalAlignment": "top",
        "fontSize": 13,
        "lineHeight": 1,
        "characterSpacing": 0,
        "fontColor": "#000000",
        "backgroundColor": "",
        "opacity": 1,
        "strikethrough": false,
        "underline": false,
        "required": false,
        "readOnly": false,
        "fontName": "Roboto"
      },
      "groupEmail": {
        "type": "text",
        "content": "groupEmail",
        "position": {
          "x": 65.72,
          "y": 104.51
        },
        "width": 78.34,
        "height": 5.76,
        "rotate": 0,
        "alignment": "left",
        "verticalAlignment": "top",
        "fontSize": 13,
        "lineHeight": 1,
        "characterSpacing": 0,
        "fontColor": "#000000",
        "backgroundColor": "",
        "opacity": 1,
        "strikethrough": false,
        "underline": false,
        "required": false,
        "readOnly": false,
        "fontName": "Roboto"
      },
      "paymentDate": {
        "type": "text",
        "content": "paymentDate",
        "position": {
          "x": 44.71,
          "y": 116.84
        },
        "width": 78.34,
        "height": 5.76,
        "rotate": 0,
        "alignment": "left",
        "verticalAlignment": "top",
        "fontSize": 13,
        "lineHeight": 1,
        "characterSpacing": 0,
        "fontColor": "#000000",
        "backgroundColor": "",
        "opacity": 1,
        "strikethrough": false,
        "underline": false,
        "required": false,
        "readOnly": false,
        "fontName": "Roboto"
      },
      "eventPeriodFull": {
        "type": "text",
        "content": "eventPeriodFull",
        "position": {
          "x": 54.71,
          "y": 122.61
        },
        "width": 78.34,
        "height": 5.76,
        "rotate": 0,
        "alignment": "left",
        "verticalAlignment": "top",
        "fontSize": 13,
        "lineHeight": 1,
        "characterSpacing": 0,
        "fontColor": "#000000",
        "backgroundColor": "",
        "opacity": 1,
        "strikethrough": false,
        "underline": false,
        "required": false,
        "readOnly": false,
        "fontName": "Roboto"
      },
      "paymentAmount": {
        "type": "text",
        "content": "paymentAmount",
        "position": {
          "x": 45.71,
          "y": 110.17
        },
        "width": 78.34,
        "height": 5.76,
        "rotate": 0,
        "alignment": "left",
        "verticalAlignment": "top",
        "fontSize": 13,
        "lineHeight": 1,
        "characterSpacing": 0,
        "fontColor": "#000000",
        "backgroundColor": "",
        "opacity": 1,
        "strikethrough": false,
        "underline": false,
        "required": false,
        "readOnly": false,
        "fontName": "Roboto"
      },
      "eventPeriodStart": {
        "type": "text",
        "content": "eventPeriodStart",
        "position": {
          "x": 84.29,
          "y": 141.34
        },
        "width": 48.18,
        "height": 5.76,
        "rotate": 0,
        "alignment": "left",
        "verticalAlignment": "top",
        "fontSize": 13,
        "lineHeight": 1,
        "characterSpacing": 0,
        "fontColor": "#000000",
        "backgroundColor": "",
        "opacity": 1,
        "strikethrough": false,
        "underline": false,
        "required": false,
        "readOnly": false,
        "fontName": "Roboto"
      },
      "eventPeriodAmount": {
        "type": "text",
        "content": "eventPeriodAmount",
        "position": {
          "x": 136.36,
          "y": 141.29
        },
        "width": 48.18,
        "height": 5.76,
        "rotate": 0,
        "alignment": "left",
        "verticalAlignment": "top",
        "fontSize": 13,
        "lineHeight": 1,
        "characterSpacing": 0,
        "fontColor": "#000000",
        "backgroundColor": "",
        "opacity": 1,
        "strikethrough": false,
        "underline": false,
        "required": false,
        "readOnly": false,
        "fontName": "Roboto"
      },
      "registredOrganisation": {
        "type": "text",
        "content": "registredOrganisation",
        "position": {
          "x": 93.5,
          "y": 212.19
        },
        "width": 48.18,
        "height": 5.76,
        "rotate": 0,
        "alignment": "left",
        "verticalAlignment": "top",
        "fontSize": 13,
        "lineHeight": 1,
        "characterSpacing": 0,
        "fontColor": "#000000",
        "backgroundColor": "",
        "opacity": 1,
        "strikethrough": false,
        "underline": false,
        "required": false,
        "readOnly": false,
        "fontName": "Roboto"
      },
      "signDateDay": {
        "type": "text",
        "content": "signDateDay",
        "position": {
          "x": 35.77,
          "y": 223.78
        },
        "width": 9.29,
        "height": 5.76,
        "rotate": 0,
        "alignment": "left",
        "verticalAlignment": "top",
        "fontSize": 13,
        "lineHeight": 1,
        "characterSpacing": 0,
        "fontColor": "#000000",
        "backgroundColor": "",
        "opacity": 1,
        "strikethrough": false,
        "underline": false,
        "required": false,
        "readOnly": false,
        "fontName": "Roboto"
      },
      "signDateMonth": {
        "type": "text",
        "content": "signDateMonth",
        "position": {
          "x": 48.68,
          "y": 223.46
        },
        "width": 9.29,
        "height": 5.76,
        "rotate": 0,
        "alignment": "left",
        "verticalAlignment": "top",
        "fontSize": 13,
        "lineHeight": 1,
        "characterSpacing": 0,
        "fontColor": "#000000",
        "backgroundColor": "",
        "opacity": 1,
        "strikethrough": false,
        "underline": false,
        "required": false,
        "readOnly": false,
        "fontName": "Roboto"
      },
      "signDateYear": {
        "type": "text",
        "content": "signDateYear",
        "position": {
          "x": 62.65,
          "y": 224.46
        },
        "width": 18.55,
        "height": 5.76,
        "rotate": 0,
        "alignment": "left",
        "verticalAlignment": "top",
        "fontSize": 13,
        "lineHeight": 1,
        "characterSpacing": 0,
        "fontColor": "#000000",
        "backgroundColor": "",
        "opacity": 1,
        "strikethrough": false,
        "underline": false,
        "required": false,
        "readOnly": false,
        "fontName": "Roboto"
      }
    },
    {}
  ],
  "basePdf": pdf,
  "pdfmeVersion": "4.0.0"
});